<template>
<div>

  <div class="container-fluid wrapper-small content-form">
    <div class="row">
      <div class="col-12">
        <div class="text-center mb-4">
          <h1 class="mb-3 anim-load1">
            Sign in to TAYC-R
          </h1>
          <p class="lead anim-load2">
            Sign in to access your therapist account
          </p>
        </div>
        <div class="card mt-3 mt-md-0 ml-md-5 mr-md-5">
          <div class="card-body">
            <ValidationObserver ref="form">
              <b-form @submit.prevent="submit" class="mb-3">
                <div class="form-group" ref="email">
                  <label for="emailInput">Email address</label>
                  <ValidationProvider v-slot="{classes, errors}" rules="required|email" name="Email address">
                    <input ref="emailInputRef" v-model="form.email" type="text" placeholder="" autocomplete="email" name="emailInput" class="form-control" v-bind:class="classes">
                    <div class="invalid-feedback">{{ errors[0] }}</div>
                  </ValidationProvider>
                </div>

                <div class="form-group" ref="password">
                  <label for="passwordInput">Password</label>
                  <ValidationProvider v-slot="{classes, errors}" rules="required" name="Password">
                    <input v-model="form.password" type="password" autocomplete="current-password" placeholder="" name="passwordInput" class="form-control" v-bind:class="classes">
                    <div class="invalid-feedback">{{ errors[0] }}</div>
                  </ValidationProvider>
                </div>

                <b-alert variant="danger"
                          dismissible
                          :show="showDismissibleAlert"
                          @dismissed="showDismissibleAlert=false">
                  {{serverError}}
                </b-alert>
                <button type="submit" class="btn btn-warning btn-lg btn-block" >Sign in</button>
              </b-form>
            </ValidationObserver>
            <div class="text-center mt-4">
              <b-link to="/password-reset/" class="btn btn-link">Forgot your password?</b-link>
            </div>          
          </div>
        </div>
        <div class="text-center mt-3">
          <p>Don't have an account? <b-link class="btn-link" to="/register-interest">Register interest</b-link></p>
        </div>
      </div>
    </div>
  </div>

  <Footer />

</div>
</template>

<script>


export default {
  name: 'Signin',
  data () {
    return {
      form: {
        email: '',
        password: ''
      },
      serverError: '',
      showDismissibleAlert: false,
      emailUpdateData: {
        newEmail: null,
        oldEmail: null,
        code: null
      }
    }
  },
  async created () {
    this.$store.state.loadingProgress = false
    this.$store.state.appSection = 'brochure'

    // If session expired, store flag changes
    if (this.$store.state.sessionExpired) {
      this.alertMsgShow('Session expired. Please sign in again.', true)
      this.$store.state.sessionExpired = false
    }

    if (this.$route.query.update === 'email') {
      this.emailUpdateData = {
        newEmail: this.$route.query.newEmail.replace(/\s/g, "+"),
        oldEmail: this.$route.query.oldEmail.replace(/\s/g, "+"),
        code: this.$route.query.code
      }
      await this.confirmUpdateEmail();
    }
    
  },

  methods: {
    confirmUpdateEmail() {
      this.$store.state.loadingProgress = true
      return this.$http({
        method: 'post',
        // headers: {'Authorization': 'bearer ' + this.$store.state.jwt },
        url: this.$api.AccountUpdateEmailConfirm,
        params: this.emailUpdateData
      })
        .then((response) => {
          console.log(response)
          this.alertMsgShow(`Email updated. Please sign in with: ${this.emailUpdateData.newEmail} `, true)
          this.$store.state.user = null
          localStorage.removeItem('jwt')
          this.$router.push('/sign-in')
        })
        .catch((error) => {
          console.log(error)
          this.alertMsgShow("Email update confirmation link expired.", false)
        })
        .then(() => {
          this.$store.state.loadingProgress = false
        })
    },

    showError(txt) {
      this.serverError = txt
      this.showDismissibleAlert = true
    },

    submit() {
      this.$refs.form.validate().then(success => {
        if (!success) {
          setTimeout(() => {
            const errors = Object.entries(this.$refs.formLogin.errors)
              .map(([key, value]) => ({ key, value }))
              .filter(error => error["value"].length);
            var el = this.$refs.formLogin.refs[errors[0]["key"]].$el
            this.$scrollTo(el, 500, {offset: -100, cancelable: false})
          }, 100);
        } else {
          this.$store.state.loadingProgress = true
          this.$http({
            method: 'post', 
            url: this.$api.AuthLogin,
            data: {
              email: this.form.email,
              password: this.form.password
            }
          })
          .then((response) => {
            console.log(response)
            var token = response.data.token
            if (token !== undefined && token !== null) {
              this.$store.state.jwt = token
              this.$store.state.signInProcess = true

              if (response.data.status === 3) {
                // user has not added phone number yet
                this.$store.state.signInProcessPhoneToAdd = token
                this.$store.state.jwtDecoded = this.$decodeToken(token)
                this.$router.push('/confirm-account/')
                .catch(error => {
                  console.info(error.message)
                })
              } else {
                // user signed up with phone before, go to phone verification
                this.$router.push('/sign-in-verification/')
                .catch(error => {
                  console.info(error.message)
                })
              }
            } else if (token === null && response.data.status === 1) {
              this.showError('Too many unsuccessful login attempts. Please try again in 5 minutes.')
            } else if (token === null && response.data.status === 2) {
              this.showError('You must verify your email before signing in. Please visit your inbox.')
            } else {
              this.showError('Incorrect username or password')
            }  
          })
          .catch((error) => {
            console.error('Error', error)
            this.showError('Incorrect username or password')
          })
          .finally(() => {
            this.$store.state.loadingProgress = false
          })
        }
      });
    }
  }
}
</script>
